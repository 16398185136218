import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class extends Controller {
  static targets = ["myChart", "symbolChart"];

  connect() {
    this.connectCharts();
  }

  disconnect() {
    this.myChart.destroy();
    this.symbolChart.destroy();
  }

  connectCharts() {
    const chartData = JSON.parse(this.myChartTarget.dataset.chartData);
    var ctx = this.myChartTarget.getContext('2d');

    var labels = chartData.map(data => data.month_year); // Měsíce
    var datasets = [];

    const colors = [
      '#A2D9CE', // Light teal
      '#85C1E9', // Light blue
      '#F7DC6F', // Soft yellow
      '#F0B27A', // Soft orange
      '#BB8FCE', // Light purple
      '#73C6B6', // Teal
      '#7FB3D5', // Blue-gray
      '#F1948A', // Soft pink-red
      '#F5B041', // Warm orange
      '#5DADE2', // Sky blue
      '#A9DFBF', // Mint green
      '#FAD7A0', // Light peach
      '#D7BDE2', // Pale purple
      '#AED6F1', // Soft sky blue
      '#A3E4D7', // Mint teal
      '#D4EFDF', // Pale green
      '#F9E79F', // Light gold
      '#FADBD8', // Soft peach-pink
      '#D5F5E3', // Pale mint
      '#E59866'  // Soft tan-orange
    ];

    chartData.forEach(data => {
      data.symbols.sort((a, b) => b.total_divi - a.total_divi); // Seřazení sestupně podle total_divi
    });
    // Najdeme všechny unikátní symboly
    var allSymbols = [...new Set(chartData.flatMap(data => data.symbols.map(symbolData => symbolData.symbol)))];

    // Připravíme dataset pro každý symbol s odpovídající barvou
    allSymbols.forEach((symbol, index) => {
      datasets.push({
        label: symbol,
        data: chartData.map(data => {
          var symbolData = data.symbols.find(s => s.symbol === symbol);
          return symbolData ? symbolData.total_divi : 0;
        }),
        backgroundColor: colors[index % colors.length], // Cyklování přes barvy
        borderColor: colors[index % colors.length],
        borderWidth: 1
      });
    });

    // Vykreslíme graf
    this.myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          legend: {
            display: true
          },
          datalabels: {
            display: true, // Zobrazení datalabels pro každou vrstvu
            color: '#FFFFFF', // Bílá barva textu
            anchor: 'center', // Umístění textu na střed vrstvy
            align: 'center', // Zarovnání textu na střed vrstvy
            font: {
              weight: 'bold'
            },
            textStrokeColor: '#000000', // Barva stínu (černá)
            textStrokeWidth: 2, // Tloušťka stínu
            textShadowColor: '#000000', // Stín textu
            textShadowBlur: 3, // Rozostření stínu
            formatter: function (value, context) {
              return value > 0 ? `${Math.round(value).toLocaleString('cs-CZ')} Kč` : '';
            }
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            beginAtZero: true
          },
          y: {
            stacked: true,
            beginAtZero: true
          }
        }
      }
    });

    // Symbol chart
    var colorMap = {};
    allSymbols.forEach((symbol, index) => {
      colorMap[symbol] = colors[index % colors.length];
    });

    // Získání dat pro spodní graf
    const symbolChartData = JSON.parse(this.symbolChartTarget.dataset.chartData);
    // Získání kontextu plátna
    var ctxSymbol = this.symbolChartTarget.getContext('2d');

    // Vykreslení grafu s popisky hodnot
    this.symbolChart = new Chart(ctxSymbol, {
      type: 'bar',
      data: {
        labels: symbolChartData.map(data => data.symbol), // X-osa: Symboly
        datasets: [{
          label: 'Příjem z dividendy podle společností v CZK',
          data: symbolChartData.map(data => data.total_divi), // Y-osa: Celkový příjem z dividend
          backgroundColor: symbolChartData.map(data => colorMap[data.symbol] || '#4BC0C0'), // Použití barvy z mapy
          borderColor: symbolChartData.map(data => colorMap[data.symbol] || '#2d7070'), // Použití barvy z mapy
          borderWidth: 1
        }]
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: '#ffffff',
            textStrokeColor: '#000000',
            textStrokeWidth: 2,
            textShadowColor: '#000000',
            textShadowBlur: 3,
            anchor: 'end',
            align: 'top',
            font: {
              weight: 'bold'
            },
            formatter: function (value, _context) {
              return `${Math.round(value).toLocaleString('cs-CZ')} Kč`;
            }
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              autoSkip: false // Umožní vykreslení všech hodnot na ose X
            }
          },
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
