import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.customSorters = {
      customNumberSorter: this.customNumberSorter,
      percentageSorter: this.percentageSorter,
      numberSorter: this.numberSorter,
      dateSorter: this.dateSorter,
      dateTimeSorter: this.dateTimeSorter,
      customSorter: this.customSorter,
      customSorterMin: this.customSorterMin
    };

    this.customFooterFormatters = {
      sumFormatterNoRounding: this.sumFormatterNoRounding,
      sumFormatterRounding: this.sumFormatterRounding
    }

    const element = this.element.querySelector('table');
    $(element).bootstrapTable();

    const options = $(element).bootstrapTable('getOptions');
    if (!options || !options.columns) {
      console.error('Failed to retrieve columns from Bootstrap Table options.');
      return;
    }

    // Dynamically replace sorter names with actual functions
    options.columns.flat().forEach(column => {
      if (column.sorter && typeof this.customSorters[column.sorter] === "function") {
        column.sorter = this.customSorters[column.sorter];
      }
      // Bind custom footer formatter functions
      if (column.footerFormatter && typeof this.customFooterFormatters[column.footerFormatter] === "function") {
        column.footerFormatter = this.customFooterFormatters[column.footerFormatter];
      }
    });

    // Reinitialize the table to apply updated sorters
    $(element).bootstrapTable('refreshOptions', options);

    // restore HTML footer
    const tfoot = element.querySelector("tfoot");
    if (!tfoot) {
      console.warn("<tfoot> not found in the table.");
    } else {
      tfoot.style.display = "table-footer-group";
    }

  }

  disconnect() {
    $(this.element.querySelector('table')).bootstrapTable('destroy');
  }

  sumFormatterNoRounding(data) {
    var field = this.field;
    var total = data.reduce(function (sum, row) {
      var value = row[field].toString().replace(/\s+/g, '').replace(',', '.');
      return sum + (parseFloat(value) || 0);
    }, 0);

    // Kontrola, zda má výsledek více než 5 desetinných míst
    if (total.toString().includes('.')) {
      var decimalPlaces = total.toString().split('.')[1].length;
      if (decimalPlaces > 5) {
        total = parseFloat(total.toFixed(5));
      }
    }

    // Vrátí výsledek s mezerami mezi tisíci
    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  sumFormatterRounding(data) {
    var field = this.field;
    var total = data.reduce(function (sum, row) {
      var value = row[field].toString().replace(/\s+/g, '').replace(',', '.');
      return sum + (parseFloat(value) || 0);
    }, 0);

    // Zaokrouhlit výsledek na celá čísla
    total = Math.round(total);

    // Vrátí výsledek s mezerami mezi tisíci
    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  dateSorter(a, b) {
    function parseDate(dateString) {
      var parts = dateString.split('. ');
      var day = parts[0];
      var month = parts[1];
      var year = parts[2];
      return new Date(`${year}-${month}-${day}`);
    }
    var dateA = parseDate(a);
    var dateB = parseDate(b);

    return dateA - dateB;
  }

  dateTimeSorter(a, b) {
    function parseDateTime(dateTimeString) {
      var [datePart, timePart] = dateTimeString.split(', ');
      var [day, month, year] = datePart.split('. ');
      var [hours, minutes] = timePart.split(':');

      return new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
    }
    var dateTimeA = parseDateTime(a);
    var dateTimeB = parseDateTime(b);
    return dateTimeA - dateTimeB;
  }

  customSorter(a, b) {
    let cleanNumber = (value, nilNumber) => value ? parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.')) || nilNumber : nilNumber;
    const nilNumber = Number.MAX_VALUE;
    a = a === '' ? nilNumber : cleanNumber(a, nilNumber);
    b = b === '' ? nilNumber : cleanNumber(b, nilNumber);
    return a - b;
  }

  customNumberSorter(a, b) {
    const cleanedA = a.replace(/\s+/g, '');
    const cleanedB = b.replace(/\s+/g, '');
    const valueA = parseFloat(cleanedA.match(/-?\d+/)[0]);
    const valueB = parseFloat(cleanedB.match(/-?\d+/)[0]);
    return valueA - valueB;
  }

  customSorterMin(a, b) {
    let cleanNumber = (value, nilNumber) => value ? parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.')) || nilNumber : nilNumber;
    const nilNumber = Number.MIN_VALUE;
    a = a === '' ? nilNumber : cleanNumber(a, nilNumber);
    b = b === '' ? nilNumber : cleanNumber(b, nilNumber);
    return a - b;
  }

  numberSorter(a, b) {
    let cleanNumber = (value) => parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'));
    return cleanNumber(a) - cleanNumber(b);
  }

  percentageSorter(a, b) {
    const getPercentage = (cell) => {
      const match = cell.match(/-?\d+(\.\d+)?%/);
      return match ? parseFloat(match[0]) : 0;
    };
    const percentageA = getPercentage(a);
    const percentageB = getPercentage(b);
    return percentageA - percentageB;
  }
}
