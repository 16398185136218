import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "fileInputLabel", "fileDropArea"];

  connect() {
    this.fileInputTarget.addEventListener('change', this.handleFiles.bind(this));
    this.fileDropAreaTarget.addEventListener('mouseover', this.handleDragOver.bind(this));
    this.fileDropAreaTarget.addEventListener('dragover', this.handleDragOver.bind(this));
    this.fileDropAreaTarget.addEventListener('dragleave', this.handleDragLeave.bind(this));
    this.fileDropAreaTarget.addEventListener('mouseleave', this.handleDragLeave.bind(this));
    this.fileDropAreaTarget.addEventListener('drop', this.handleDrop.bind(this));
  }

  disconnect() {
    this.fileInputTarget.removeEventListener('change', this.handleFiles.bind(this));
    this.fileDropAreaTarget.removeEventListener('mouseover', this.handleDragOver.bind(this));
    this.fileDropAreaTarget.removeEventListener('dragover', this.handleDragOver.bind(this));
    this.fileDropAreaTarget.removeEventListener('dragleave', this.handleDragLeave.bind(this));
    this.fileDropAreaTarget.removeEventListener('mouseleave', this.handleDragLeave.bind(this));
    this.fileDropAreaTarget.removeEventListener('drop', this.handleDrop.bind(this));
  }

  handleFiles() {
    if (this.fileInputTarget.files && this.fileInputTarget.files.length > 0) {
      this.element.submit();
      this.fileInputLabelTarget.textContent = 'Nahrávám soubory...';
      this.fileInputTarget.value = ''; // Reset input value after submission
    }
  }

  handleDragOver(event) {
    event.preventDefault();
    this.fileDropAreaTarget.style.backgroundColor = '#eae9e9';
  }

  handleDragLeave() {
    this.fileDropAreaTarget.style.backgroundColor = 'transparent';
  }

  handleDrop(event) {
    event.preventDefault();
    this.fileDropAreaTarget.style.backgroundColor = 'transparent';
    this.fileInputTarget.files = event.dataTransfer.files;
    this.handleFiles();
  }
}
