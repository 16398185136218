import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = { options: Object }

  connect() {
    let options = {
      create: false,                 // Povolit vlastní vstup
      theme: "bootstrap5",
      maxItems: 1,                   // Pouze jeden výběr
      allowEmptyOption: false,       // Povolit prázdnou možnost
      sortField: {
        field: "text",
        direction: "asc"
      }
    }

    if (this.element.dataset.noSort) {
      delete options["sortField"]
    }
    this.select = new TomSelect(this.element, options);
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}