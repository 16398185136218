import { Controller } from "@hotwired/stimulus"
import * as d3 from "https://cdn.jsdelivr.net/npm/d3@7/+esm"

export default class extends Controller {
  static targets = ["my_dataviz"];

  connect() {
    let chartData = JSON.parse(this.my_datavizTarget.dataset.chartData);
    this.createHeatMapGraph(this.my_datavizTarget, chartData);

    window.addEventListener("resize", this.resizeHeatMap.bind(this));
  }

  disconnect() {
    d3.select(this.my_datavizTarget).selectAll("svg").remove();
    window.removeEventListener("resize", this.resizeHeatMap.bind(this));
  }

  resizeHeatMap() {
    let new_width = document.getElementById('heat-map').clientWidth - 30;
    d3.select(this.my_datavizTarget).selectAll("svg").attr("width", new_width)
      .attr("height", 580);
  }

  createHeatMapGraph(container, data) {
    let width = document.getElementById('heat-map').clientWidth - 30; // Získá aktuální šířku elementu
    let height = 580; // Nastaví výšku na 600 pixelů

    let color = d3.scaleLinear()
      .domain([-100, -40, -10, 0, 10, 40, 350]) // Definice bodů, kde se barvy mění
      .range(["#570519", "#910505F2", "#cc443d", "#dedede",
        "#66bd63", "#116c38", "#03381e"]); // Definované barvy

    let treemap = d3.treemap()
      .size([width, height])
      .padding(1);

    let root = d3.hierarchy({
      name: "portfolio",
      children: data
    }).sum(function (d) { return d.value; });

    treemap(root);

    if (container) {
      container.innerHTML = "";
    }
    let svg = d3.select(container)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`);

    let tooltip = d3.select("body").append("div")
      .attr("class", "tooltip");

    let leaf = svg.selectAll("g")
      .data(root.leaves())
      .enter().append("g")
      .attr("transform", function (d) { return "translate(" + d.x0 + "," + d.y0 + ")"; });

    leaf.append("rect")
      .attr("width", function (d) { return d.x1 - d.x0; })
      .attr("height", function (d) { return d.y1 - d.y0; })
      .attr("fill", function (d) { return color(d.data.gain); })
      .on("mouseover", function (event, d) {
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        tooltip.html("Akcie: " + d.data.name + "<br/>" + "Pozice: " + d.data.value + "%" + "<br/>" + "Zisk/Ztráta: " + d.data.gain + "%")
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

    leaf.append("text")
      .attr("x", function (d) { return (d.x1 - d.x0) / 2; })
      .attr("y", function (d) { return (d.y1 - d.y0) / 2 - 10; }) // Posune text trochu nahoru
      .attr("text-anchor", "middle")
      .text(function (d) { return d.data.name.length > 15 ? d.data.name.substring(0, 15) + '...' : d.data.name; })
      .style("fill", function (d) { return d3.hsl(color(d.data.gain)).l < 0.5 ? "#fff" : "#000"; });

    // Přidání druhého řádku pro zisk/ztrátu
    leaf.append("text")
      .attr("x", function (d) { return (d.x1 - d.x0) / 2; })
      .attr("y", function (d) { return (d.y1 - d.y0) / 2 + 10; }) // Posune text trochu dolů
      .attr("text-anchor", "middle")
      .text(function (d) { return d.data.gain + '%'; })
      .style("fill", function (d) { return d3.hsl(color(d.data.gain)).l < 0.5 ? "#fff" : "#000"; });
    return
  }
}