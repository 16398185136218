import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.modal = new bootstrap.Modal(this.element);
    this.modal.show();

    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove();
    });

    this.keyupHandler = this.keyupHandler.bind(this);
    window.addEventListener('keyup', this.keyupHandler);
  }

  disconnect() {
    if (this.modal) {
      this.modal.hide();
    }
    window.removeEventListener('keyup', this.keyupHandler);
  }

  keyupHandler(e) {
    if (e.code === "Escape") {
      this.hideModal();
    } else if (e.code === "Enter") {
      this.submitForm();
    }
  }

  // hide modal
  // action: "modal#hideModal"
  hideModal() {
    this.element.parentElement.removeAttribute("src")
    this.modal.hide();
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  // Submit form inside the modal
  submitForm(e) {
    e.preventDefault();
    const form = this.element.querySelector("form");
    if (form) {
      // Check if the form is already submitting to prevent multiple submissions
      if (!form.dataset.submitting) {
        form.dataset.submitting = true;
        form.requestSubmit();
      }
    }
  }
}
