import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["frame"]

  connect() {
    this.modal = new bootstrap.Modal(this.element);

    this.element.addEventListener("hidden.bs.modal", () => {
      this.frameTarget.src = "";
      this.frameTarget.innerHTML = "";
    });
  }

  disconnect() {
    this.modal.dispose();
  }

  open() {
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  submit(event) {
    if (event.detail.success) {
      this.close();
    }
  }

  frameMissing(event) {
    event.preventDefault();
    if (event.detail.response.redirected) {
      event.detail.visit(event.detail.response.url)
    }
  }
}
