import { Controller } from "@hotwired/stimulus"
import * as d3 from "https://cdn.jsdelivr.net/npm/d3@7/+esm"

export default class extends Controller {
  static targets = ["investedByCurrencyChart", "investedByCountryChart", "stocksChart"];

  connect() {
    const colors = [
      '#1E90FF', '#32CD32', '#FFD700', '#FF4500', '#8A2BE2', '#20B2AA', '#FF69B4', '#FF8C00', '#00CED1',
      '#9400D3', '#ADFF2F', '#4682B4', '#FF1493', '#00FA9A', '#FF6347', '#B22222', '#87CEEB', '#FFB6C1',
      '#CD5C5C', '#FFB6C1', '#DC143C', '#00FF7F', '#8B4513', '#FF6347', '#4169E1', '#FFD700', '#FF1493'
    ];
    let currencyData = JSON.parse(this.investedByCurrencyChartTarget.dataset.currencyData);
    currencyData.sort((a, b) => b.currentPercentage - a.currentPercentage);
    this.createStreamGraph(
      this.investedByCurrencyChartTarget,
      currencyData.map(d => d.currency),
      currencyData.map(d => d.originalPercentage),
      currencyData.map(d => d.currentPercentage),
      colors
    );

    let countryData = JSON.parse(this.investedByCountryChartTarget.dataset.countryData);
    countryData.sort((a, b) => b.currentPercentage - a.currentPercentage);
    this.createStreamGraph(
      this.investedByCountryChartTarget,
      countryData.map(d => d.country),
      countryData.map(d => d.originalPercentage),
      countryData.map(d => d.currentPercentage),
      colors
    );

    let stocksData = JSON.parse(this.stocksChartTarget.dataset.stocksData);
    this.createStocksGraph(
      this.stocksChartTarget,
      stocksData.map(d => d.symbol),
      stocksData.map(d => d.original_percentage),
      stocksData.map(d => d.current_percentage),
      colors
    );
  }

  disconnect() {
    d3.select(this.investedByCurrencyChartTarget).selectAll("svg").remove();
    d3.select(this.investedByCountryChartTarget).selectAll("svg").remove();
    d3.select(this.stocksChartTarget).selectAll("svg").remove();
  }

  createStreamGraph(container, labels, originalData, currentData, colors) {
    // Zjistíme šířku okna (viewportu)
    let windowWidth = window.innerWidth;
    let isMobile = windowWidth <= 768;  // Detekujeme mobilní zařízení (šířka <= 768px)

    // Rozměry grafu - pokud je to mobilní zařízení, použijeme užší šířku a výšku
    let margin = { top: 5, right: 70, bottom: 30, left: 70 },
      width = isMobile ? windowWidth - margin.left - margin.right - 20 : 450 - margin.left - margin.right,  // Užší šířka pro mobil
      height = isMobile ? 300 - margin.top - margin.bottom : 425 - margin.top - margin.bottom;  // Užší výška pro mobil

    let svg = d3.select(container)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Připravíme data pro stack layout
    let data = [
      Object.assign({ date: "Zainvestováno" }, ...labels.map((label, i) => ({ [label]: originalData[i] }))),
      Object.assign({ date: "Aktuálně" }, ...labels.map((label, i) => ({ [label]: currentData[i] })))
    ];

    // Měřítka
    let x = d3.scalePoint()
      .domain(data.map(d => d.date))
      .range([0, width]);

    let y = d3.scaleLinear()
      .domain([0, 100])
      .range([height, 0]);

    let color = d3.scaleOrdinal()
      .domain(labels)
      .range(colors.slice(0, labels.length));

    // Vytvoření stack layoutu
    let stack = d3.stack()
      .keys(labels);

    let series = stack(data);

    // Oblast pro streamgraph
    let area = d3.area()
      .x(d => x(d.data.date))
      .y0(d => y(d[0]))
      .y1(d => y(d[1]));

    // Přidání oblastí pro každou kategorii
    svg.selectAll(".layer")
      .data(series)
      .enter().append("path")
      .attr("class", "layer")
      .attr("d", area)
      .attr("fill", d => color(d.key))
      .attr("stroke", "#000")
      .attr("opacity", 0.7);

    // Přidání popisků pro tickery uvnitř vrstev
    series.forEach(layer => {
      svg.append("text")
        .attr("class", "ticker-label")
        .datum(layer[layer.length - 1]) // Používáme poslední segment vrstvy pro správné umístění popisku
        .attr("x", width / 2)  // Položíme label do středu grafu (pro oba stavy)
        .attr("y", function (d) {
          return (y(d[0]) + y(d[1])) / 2; // Střední hodnota vrstvy
        })
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .attr("fill", "#fff")
        .attr("font-weight", "bold")
        .attr("style", "text-shadow: 1px 1px 2px #000;")
        .text(layer.key);
    });

    // Přidání procent na levý a pravý kraj grafu
    series.forEach(layer => {
      layer.forEach((segment, index) => {
        if (index === 0) {
          svg.append("text")
            .attr("x", x("Zainvestováno") - 20)
            .attr("y", (y(segment[0]) + y(segment[1])) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "end")
            .attr("fill", "#000")
            .attr("font-size", "14px")
            .text(`${segment.data[layer.key]} %`);
        }

        if (index === 1) {
          svg.append("text")
            .attr("x", x("Aktuálně") + 20)
            .attr("y", (y(segment[0]) + y(segment[1])) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "start")
            .attr("fill", "#000")
            .attr("font-size", "14px")
            .text(`${segment.data[layer.key]} %`);
        }
      });
    });

    // Přidání popisků "Zainvestováno" a "Aktuálně" - Opravené umístění
    svg.append("text")
      .attr("x", x("Zainvestováno"))
      .attr("y", height + margin.bottom / 2)  // Posunuli jsme Y, aby bylo vidět
      .attr("dy", "0.45em")
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      .text("Zainvestováno");

    svg.append("text")
      .attr("x", x("Aktuálně"))
      .attr("y", height + margin.bottom / 2)  // Posunuli jsme Y, aby bylo vidět
      .attr("dy", "0.45em")
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      .text("Aktuálně");
    return
  }

  createStocksGraph(container, stocks, originalPercentages, currentPercentages, colors) {
    let data = [
      Object.assign({ date: "Zainvestováno" }, ...stocks.map((stock, i) => ({ [stock]: originalPercentages[i] }))),
      Object.assign({ date: "Aktuálně" }, ...stocks.map((stock, i) => ({ [stock]: currentPercentages[i] })))
    ];

    // Vypočítáme maximální možný součet hodnot (kvůli kompenzacím může být vyšší než 100 %)
    let maxSum = d3.max(data, d => d3.sum(stocks, key => d[key]));

    let windowWidth = window.innerWidth;
    let isMobile = windowWidth <= 768;  // Detekujeme mobilní zařízení (šířka <= 768px)

    // Rozměry grafu akcií
    let margin = { top: 5, right: 70, bottom: 30, left: 70 },
      width = isMobile ? windowWidth - margin.left - margin.right - 20 : 500 - margin.left - margin.right,  // Užší šířka pro akcie
      height = 870;


    let svg = d3.select(container)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Měřítka
    let x = d3.scalePoint()
      .domain(data.map(d => d.date))
      .range([0, width]);

    // Dynamicky nastavená osa Y podle maximálního součtu
    let y = d3.scaleLinear()
      .domain([0, maxSum])  // Dynamický rozsah podle maximálního součtu
      .range([height, 0]);

    // Barva: pokud symbol obsahuje "compensation", použij bílou barvu, jinak použij normální barvy
    let color = d3.scaleOrdinal()
      .domain(stocks)
      .range(stocks.map(stock => stock.includes("compensation") ? "#ffffff" : colors[stocks.indexOf(stock)]));

    // Vytvoření stack layoutu pro graf akcií
    let stack = d3.stack()
      .keys(stocks);

    let series = stack(data);

    // Oblast pro streamgraph
    let area = d3.area()
      .x(d => x(d.data.date))
      .y0(d => y(d[0]))
      .y1(d => y(d[1]));

    // Přidání oblastí pro každou akcii
    svg.selectAll(".layer")
      .data(series)
      .enter().append("path")
      .attr("class", "layer")
      .attr("d", area)
      .attr("fill", d => color(d.key))  // Barvení prvků
      .attr("stroke", "#000")
      .attr("opacity", 0.7);

    // Přidání popisků pro tickery uvnitř vrstev
    series.forEach(layer => {
      if (!layer.key.includes("compensation")) {  // Nezobrazujeme popisky pro "compensation"
        svg.append("text")
          .attr("class", "ticker-label")
          .datum(layer[layer.length - 1]) // Používáme poslední segment vrstvy pro správné umístění popisku
          .attr("x", width / 2)  // Položíme label do středu grafu (pro oba stavy)
          .attr("y", function (d) {
            return (y(d[0]) + y(d[1])) / 2; // Střední hodnota vrstvy
          })
          .attr("dy", "0.35em")
          .attr("text-anchor", "middle")
          .attr("fill", "#fff")
          .attr("font-weight", "bold")
          .attr("style", "text-shadow: 1px 1px 2px #000;")  // Černý stín kolem textu
          .text(layer.key);
      }
    });

    // Přidání procent na levý a pravý kraj grafu
    series.forEach(layer => {
      layer.forEach((segment, index) => {
        if (!layer.key.includes("compensation")) {  // Nezobrazujeme procenta pro "compensation"
          // Původní stav (levý kraj)
          if (index === 0) {
            svg.append("text")
              .attr("x", x("Zainvestováno") - 20) // Mírně vlevo od bodu "Zainvestováno"
              .attr("y", (y(segment[0]) + y(segment[1])) / 2)
              .attr("dy", "0.35em")
              .attr("text-anchor", "end")
              .attr("fill", "#000")
              .attr("font-size", "14px")  // Zvýšení velikosti písma pro lepší čitelnost
              .text(`${segment.data[layer.key]} %`);
          }

          // Aktuální stav (pravý kraj)
          if (index === 1) {
            svg.append("text")
              .attr("x", x("Aktuálně") + 20) // Mírně vpravo od bodu "Aktuálně"
              .attr("y", (y(segment[0]) + y(segment[1])) / 2)
              .attr("dy", "0.35em")
              .attr("text-anchor", "start")
              .attr("fill", "#000")
              .attr("font-size", "14px")  // Zvýšení velikosti písma pro lepší čitelnost
              .text(`${segment.data[layer.key]} %`);
          }
        }
      });
    });

    // Přidání osy X
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickSize(0).tickPadding(10))
      .selectAll("text")
      .attr("font-size", "16px")  // Zvýšení velikosti písma pro "Zainvestováno" a "Aktuálně"
      .attr("font-weight", "bold");
    return
  }
}
